import BlockItem from 'page/Editor/configuration/BlockItem';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { Selected } from 'page/Editor/Scene';
import { Device } from 'types';
import { getStyleAttribute } from 'utils';

export const usePremiumKnobs = ({ selection, availableDevices }: { selection: Device | Selected; availableDevices: Device[] }) => {
  const handleAddPremiumKnobs = () => {
    if (selection instanceof BlockItem) {
      const amountOfDeviceKnobs = selection?.misc?.knobs ?? 0;

      if (!amountOfDeviceKnobs) return;
      EquipmentHelper.setPremiumKnob(
        selection,
        availableDevices.find(d => {
          const knobAmount = getStyleAttribute(d.style, 'Knobs');
          return (
            //@ts-expect-error
            d.subtype === 'PremiumKnob' && knobAmount === amountOfDeviceKnobs.toString()
          );
        })
      );
    }
  };

  const handleRemovePremiumKnobs = () => {
    if (selection instanceof BlockItem) {
      EquipmentHelper.setPremiumKnob(selection, null);
    }
  };

  return {
    onAddPremiumKnobs: handleAddPremiumKnobs,
    onRemovePremiumKnobs: handleRemovePremiumKnobs
  };
};
