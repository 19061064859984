import { LoadingSpinner } from 'components';
import { useGetStartNotification } from 'page/Home/useGetStartNotification';

export const StartNotification = () => {
  const { isLoading: isLoadingStartNotification, startNotification } = useGetStartNotification();

  return (
    <div className="start-notification">
      {isLoadingStartNotification ? <LoadingSpinner size="32px" /> : <p dangerouslySetInnerHTML={{ __html: startNotification.replace(/\n/g, '<br>') }}></p>}
    </div>
  );
};
