import { EditorContext } from 'context';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Device } from 'types';
import { getNOLWingedDoorDevice } from 'utils/getNOLWingedDoorDevice';

const STORAGE_KEY = 'selectedVariant_device_';

export const useVariantSelection = ({ device }: { device: Device }) => {
  const { selection, setVariantKey } = useContext(EditorContext);
  const [isParentLoading, setIsParentLoading] = useState(false);
  const [isChildrenLoading, setIsChildrenLoading] = useState(false);

  const [parentId, setParentId] = useState<string | null>(device?.parent ?? null);
  const [childrenIds, setChildrenIds] = useState<string[]>(device?.children ?? []);

  const [parentDevice, setParentDevice] = useState<Device | null>(null);
  const [childrenDevices, setChildrenDevices] = useState<Device[]>([]);

  const [selectedVariantId, setSelectedVariantId] = useState<string>(device.id);

  useEffect(() => {
    setParentId(device?.parent ?? device.id);
    setSelectedVariantId(device.id);
  }, [device]);

  useEffect(() => {
    setChildrenIds(parentDevice?.children ?? []);
  }, [parentDevice]);

  useEffect(() => {
    if (parentId) {
      setIsParentLoading(true);

      if (localStorage.getItem(`${STORAGE_KEY}${parentId}`)) {
        const data = JSON.parse(localStorage.getItem(`${STORAGE_KEY}${parentId}`) as string);
        setParentDevice(data as Device);
        setIsParentLoading(false);
      } else {
        fetch(`${process.env.REACT_APP_API_URL}/device/get/${parentId}`)
          .then(response => response.json())
          .then(data => {
            setParentDevice(data.result);
          });
      }
      setIsParentLoading(false);
    }
  }, [parentId]);

  useEffect(() => {
    if (childrenIds.length > 0) {
      setIsChildrenLoading(true);

      Promise.all(
        childrenIds.map(async id => {
          if (localStorage.getItem(`${STORAGE_KEY}${id}`)) {
            return JSON.parse(localStorage.getItem(`${STORAGE_KEY}${id}`) as string);
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/device/get/${id}`);
          const data = await response.json();
          return data.result;
        })
      )
        .then(data => {
          setChildrenDevices(data as Device[]);
        })
        .finally(() => {
          setIsChildrenLoading(false);
        });
    }
  }, [childrenIds]);

  const handleChangeVariant = async (deviceId: string) => {
    const newDevice = availableVariants.find(d => d.id === deviceId);

    setSelectedVariantId(deviceId);
    if (selection instanceof BlockItem) {
      selection.setDeviceObject(newDevice);
      setVariantKey(newDevice.id + selection.getUniqueId());

      // Wenn wingeddoor mal wieder rein soll, hier wieder einkommentieren
      // if (newDevice.model.modularNOL) {
      if (!EquipmentHelper.canHaveWingedDoor(selection)) {
        EquipmentHelper.setWingedDoor(selection, null);
      }
      //   if (EquipmentHelper.canHaveWingedDoor(selection)) {
      //     const wingedDoorDevice = await getNOLWingedDoorDevice(selection);

      //     EquipmentHelper.setWingedDoor(selection, wingedDoorDevice);
      //   } else {
      //     EquipmentHelper.setWingedDoor(selection, null);
      //   }
      // }
    }
  };

  const availableVariants = useMemo(() => {
    return [parentDevice, ...childrenDevices];
  }, [parentDevice, childrenDevices]);

  return {
    availableVariants,
    isLoading: isParentLoading || isChildrenLoading,
    onChangeVariant: handleChangeVariant,
    selectedVariantId
  };
};
