import { useEffect, useState } from 'react';
import { get } from 'utils';

export const useGetStartNotification = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [startNotification, setStartNotification] = useState('');

  useEffect(() => {
    const fetchStartNotification = async () => {
      try {
        const { data, error } = await get(`${process.env.REACT_APP_API_URL}/misc/get/start.notification`);
        if (error) {
          console.error('Error fetching start notification:', error);
          return;
        }
        if (data) {
          setStartNotification(data.value ?? '');
        }
      } catch (error) {
        console.error('Error fetching start notification:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStartNotification();
  }, []);

  return {
    isLoading,
    startNotification
  };
};
