import React, { FC, useState, useContext } from 'react';

// Assets
import ArrowLeft from 'assets/svg/Icon_Arrow.svg';

// Components:
import { ASimple, Icon, Logo } from 'atoms';
import Navigation from 'components/Navigation';

// Context:
import { EditorContext, LangContext } from 'context';

// Partials:
import { ModeSelector } from './index';

// Languages:
import Localization from 'languages';

// Styles:
import { colors } from 'styles/theme';

// Types
import { SavedConfiguration } from 'types';
import { useUser } from 'context/auth-context';

// Consts:
const DELAY = 750;

// ==============================================================
interface Props {
  savedConfiguration: SavedConfiguration;
  isEditor: boolean;
  configIsOpen: boolean;
  setConfigIsOpen: (status: boolean) => void;
}

// ==============================================================
export const HeaderNavigation: FC<Props> = ({ savedConfiguration, isEditor, configIsOpen, setConfigIsOpen }) => {
  const { lang } = useContext(LangContext);
  const { hasFlexiAccess, hasMarineMeisterAccess, hasMasterlineAccess, hasModularAccess, hasModularNOLAccess, hasRoomAccess, hasSpaceAccess } = useUser();
  const { configuration, scene, setMarineMeisterView, setMasterlineView, setModularView, setModularNOLView, setRoomView, setSelection } =
    useContext(EditorContext);
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);

  return (
    <div className="Header-Navigation" style={{ background: isEditor ? 'white' : 'transparent' }}>
      {/* Left Navigation Part */}
      <div className="Header-Navigation-Collection">
        {/* Hamburger Navigation */}
        <div className="Header-Navigation-EdgeItem">
          <Icon type="menu" size="24px" color={isEditor ? 'medium' : 'white'} onClick={() => setNavIsOpen(!navIsOpen)} />
        </div>

        {/* Editor Elements */}
        {isEditor && <div className="Header-Navigation-Separation" style={{ background: configIsOpen ? colors.grey : colors.light }} />}
        {configuration && isEditor && (
          /* Configuration Overview Navigation */
          <>
            {configIsOpen && (
              <ASimple key="back" className="Header-Navigation-Item" delay={DELAY}>
                <img
                  alt="closeNavigation"
                  onClick={() => setConfigIsOpen(false)}
                  src={ArrowLeft}
                  style={{ cursor: 'pointer', width: '34px', transform: 'rotate(180deg)' }}
                />
              </ASimple>
            )}

            {/* Mode Selectors */}
            {hasRoomAccess && !configIsOpen && (
              <ASimple key="room" className="Header-Navigation-Item" delay={DELAY}>
                <ModeSelector
                  mode="room"
                  reset={() => {
                    if (scene) {
                      scene.setSelected(null);
                    }
                    setSelection(null);
                    setRoomView('home');
                  }}
                />
              </ASimple>
            )}
            {hasFlexiAccess && !configIsOpen && (
              <ASimple key="flexiChef" className="Header-Navigation-Item" delay={DELAY}>
                <ModeSelector
                  mode="flexiChef"
                  reset={() => {
                    if (scene) {
                      scene.setSelected(null);
                    }
                    setSelection(null);
                  }}
                />
              </ASimple>
            )}
            {hasSpaceAccess && !configIsOpen && (
              <ASimple key="spaceCombi" className="Header-Navigation-Item" delay={DELAY}>
                <ModeSelector
                  mode="spaceCombi"
                  reset={() => {
                    if (scene) {
                      scene.setSelected(null);
                    }
                    setSelection(null);
                  }}
                />
              </ASimple>
            )}
            {hasModularAccess && !configIsOpen && (
              <ASimple key="modular" className="Header-Navigation-Item" delay={DELAY}>
                <ModeSelector
                  mode="modular"
                  reset={() => {
                    if (scene) {
                      scene.setSelected(null);
                    }
                    setSelection(null);
                    setModularView('home');
                  }}
                />
              </ASimple>
            )}
            {hasModularNOLAccess && !configIsOpen && (
              <ASimple key="modular-new" className="Header-Navigation-Item" delay={DELAY}>
                <ModeSelector
                  mode="modularNOL"
                  reset={() => {
                    if (scene) {
                      scene.setSelected(null);
                    }
                    setSelection(null);
                    setModularNOLView('home');
                  }}
                />
              </ASimple>
            )}
            {hasMasterlineAccess && !configIsOpen && (
              <ASimple key="masterline" className="Header-Navigation-Item" delay={DELAY}>
                <ModeSelector
                  mode="masterline"
                  reset={() => {
                    if (scene) {
                      scene.setSelected(null);
                    }
                    setSelection(null);
                    setMasterlineView('home');
                  }}
                />
              </ASimple>
            )}
            {hasMarineMeisterAccess && !configIsOpen && (
              <ASimple key="marineMeister" className="Header-Navigation-Item" delay={DELAY}>
                <ModeSelector
                  mode="marineMeister"
                  reset={() => {
                    if (scene) {
                      scene.setSelected(null);
                    }
                    setSelection(null);
                    setMarineMeisterView('home');
                  }}
                />
              </ASimple>
            )}
          </>
        )}
      </div>

      {/* Editor Configuration & Logo */}
      {isEditor && !configIsOpen && (
        <ASimple key="configuration" className="Header-Navigation-Item" style={{ width: '215px' }} delay={DELAY}>
          <div className="Header-Navigation-Item-Content" onClick={() => setConfigIsOpen(!configIsOpen)}>
            <span className="Header-Navigation-Item-Content-Heading">{Localization['myConfig'][lang]}</span>
            <span className="Header-Navigation-Item-Content-Text">
              {savedConfiguration ? savedConfiguration.name : ''}
              <Icon type="arrowRight" stroke={1} size="0.6rem" color="medium" margin="0 0 0 0.25rem" />
            </span>
          </div>
        </ASimple>
      )}

      {/* Editor Logo */}
      {isEditor && (
        <div className="Header-Navigation-EdgeItem">
          <Logo />
        </div>
      )}

      {/* Navigation Portal */}
      <Navigation open={navIsOpen} close={() => setNavIsOpen(false)} />
    </div>
  );
};
