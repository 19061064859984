import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Portal } from 'react-portal';

// Components
import { Accordion, DeviceTileSmall, LoadingSpinner } from 'components';
import BlockItem from 'page/Editor/configuration/BlockItem';

// Context
import { EditorContext, LangContext } from 'context';

// Lang
import Localizations from 'languages';

// Types
import { Category, Device, DevicePosition, DeviceSeries, ErrorAlert } from 'types';

// Utils
import { createDeviceList, isFryer, post } from 'utils';

// Styles
import { colors } from 'styles/theme';
import './style.scss';
import { isSubstructureClashing } from 'utils/isSubstructureClashing';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { addHygiene } from 'utils/addDevice';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import { getNOLWingedDoorDevice } from 'utils/getNOLWingedDoorDevice';

// ===================================================================
interface Props {
  category: Category;
  isLast?: boolean;
  setSeries: (ser: DeviceSeries) => void;
  selectDevice?: (d: Device) => void;
  connectionFilter?: string;
  operatingFilter?: string;
}

// ===================================================================
export const Subcategory: FC<Props> = ({ category, connectionFilter, operatingFilter, selectDevice }) => {
  const {
    configuration,
    scene,
    selectedMasterline,
    selectedMarineMeister,
    selectedModular,
    selectedModularNOL,
    seriesFilter,
    setErrorAlert,
    setSeriesFilter,
    menuView
  } = useContext(EditorContext);
  const block =
    menuView === 'modular' ? selectedModular : menuView === 'modularNOL' ? selectedModularNOL : menuView === 'masterline' ? selectedMasterline : null;

  const { langId, lang } = useContext(LangContext);

  const connectionOptions = [Localizations['all'][lang], '1 NPE AC', '2 NPE AC', '3 NPE AC'];
  const operatingModeOptions = [Localizations['all'][lang], 'Electro', 'Gas', 'Steam'];

  const [devices, setDevices] = useState<Device[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(false);
  const [draggingInScene, setDraggingInScene] = useState<boolean>(false);
  const [deviceList, setDeviceList] = useState<DevicePosition[]>([]);
  const unmounted = useRef(false);

  const voltage = useMemo(() => (menuView === 'marineMeister' ? selectedMarineMeister?.getVoltage() : 0), [menuView, selectedMarineMeister]);
  const key = `devices-category-${category ? category.id : ''}${menuView}-${voltage}`;

  const refreshList = () => {
    setFilteredDevices([...filteredDevices]);
  };

  useEffect(() => {
    if (devices && !unmounted.current) {
      let newFilteredDevices = [...devices];
      if (connectionFilter != connectionOptions[0]) {
        newFilteredDevices = newFilteredDevices.filter(d => d.energy.electroConditions.includes(connectionFilter));
      }
      if (operatingFilter != operatingModeOptions[0]) {
        //@ts-ignore
        newFilteredDevices = newFilteredDevices.filter(d => d.energy.source == operatingFilter);
      }
      if (seriesFilter === '850' || seriesFilter === '700') {
        newFilteredDevices = newFilteredDevices.filter(d => d.model.depths.includes(parseInt(seriesFilter)));
      }
      setFilteredDevices(newFilteredDevices);
    }
  }, [connectionFilter, devices, operatingFilter, seriesFilter]);

  useEffect(() => {
    // Workaround
    if (seriesFilter && !(seriesFilter === '700' || seriesFilter === '850') && !unmounted.current) {
      setSeriesFilter('all');
    }
    setLoading(true);
    const fetchDevices = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/devices`, {
        data: {
          basicOnly: true,
          type: menuView,
          category: {
            id: category.id
          },
          voltage: menuView === 'marineMeister' ? voltage : undefined
        }
      });
      if (data) {
        setDevices(data);
        sessionStorage.setItem(key, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedDevices = sessionStorage.getItem(key);
    if (cachedDevices) {
      setDevices(JSON.parse(cachedDevices));
    } else {
      fetchDevices();
    }
    createDeviceList(block, setDeviceList);
    setLoading(false);

    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (seriesFilter && seriesFilter !== 'all' && seriesFilter !== Localizations['all'][lang] && !unmounted.current) {
      setFilteredDevices(devices.filter(d => d.model.depths.includes(parseInt(seriesFilter))));
    } else {
      setFilteredDevices(devices);
    }
  }, [devices, seriesFilter]);

  return (
    <Accordion classes="Subcategory-Accordion" title={category.translations[langId].name}>
      <div className="Subcategory-Panel">
        {loading && <LoadingSpinner color="blue" />}
        <DragDropContext
          onDragUpdate={initial => {
            if (scene && initial.destination && initial.destination.droppableId.includes('Droppable-Devices-Scene') && !draggingInScene) {
              if (
                (filteredDevices[initial.source.index].model.flexiChef || filteredDevices[initial.source.index].model.spaceCombi) &&
                menuView !== 'modular' &&
                menuView !== 'masterline'
              ) {
                scene.onDragOver(devices[initial.source.index], null, configuration);
              } else {
                scene.onDragOver(
                  filteredDevices[initial.source.index],
                  filteredDevices[initial.source.index].model.modular && menuView === 'modular'
                    ? selectedModular
                    : filteredDevices[initial.source.index].model.modular && menuView === 'modularNOL'
                    ? selectedModularNOL
                    : filteredDevices[initial.source.index].model.masterline && menuView === 'masterline'
                    ? selectedMasterline
                    : block
                );
              }
              setDraggingInScene(true);
              document.getElementById(`${initial.draggableId}-Div`).style.display = 'none';
            } else if (scene && (!initial.destination || initial.destination.droppableId === 'Droppable-Devices') && draggingInScene) {
              scene.onDragOut();
              setDraggingInScene(false);
              document.getElementById(`${initial.draggableId}-Div`).style.display = 'block';
            }
          }}
          onDragEnd={async () => {
            setDraggingInScene(false);
            // Arbeitstisch
            const selection = scene.getSelected();
            if (block.isAutoHygiene() && (selection instanceof BlockItem || selection instanceof BlockGroup) && EquipmentHelper.canHaveHygiene(selection)) {
              addHygiene({ item: selection, depth: block.getRowTop().getDepth() });
            }
            if (selection && selection instanceof BlockItem) {
              const errorAlerts: ErrorAlert[] = [];

              const deviceObject = selection.getDeviceObject();
              if (deviceObject.category && isFryer(category)) {
                errorAlerts.push('fryer');
              }

              if (deviceObject?.dependency?.coverEnlargement) {
                errorAlerts.push('coverEnlargement');
              }

              if (deviceObject?.model?.flexiChef && menuView === 'masterline') {
                errorAlerts.push('flexiMasterlineHint');
              }
              if (errorAlerts.length > 0) {
                setErrorAlert(errorAlerts);
              }
            }
            if (
              block &&
              menuView === 'modularNOL' &&
              (selection instanceof BlockItem || selection instanceof BlockGroup) &&
              EquipmentHelper.canHaveWingedDoor(selection)
            ) {
              const wingedDoorDevice = await getNOLWingedDoorDevice(selection);
              EquipmentHelper.setWingedDoor(selection, wingedDoorDevice);
            }
            // ==================================================
          }}
        >
          {/* Menu */}
          <Droppable droppableId="Droppable-Devices" ignoreContainerClipping isDropDisabled>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {filteredDevices &&
                  filteredDevices.length > 0 &&
                  filteredDevices.map((device: Device, index: number) => {
                    const itemAddable = device.model.flexiChef
                      ? true
                      : device.model.spaceCombi
                      ? true
                      : device.model.modular
                      ? selectedModular
                        ? selectedModular.canItemBeDropped(device, selectedModular.getRowTop()) ||
                          selectedModular.canItemBeDropped(device, selectedModular.getRowBottom())
                        : false
                      : device.model.modularNOL
                      ? selectedModularNOL
                        ? selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowTop()) ||
                          selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowBottom())
                        : false
                      : block
                      ? !isSubstructureClashing(device, selectedMasterline) &&
                        (block.canItemBeDropped(device, block.getRowTop()) || block.canItemBeDropped(device, block.getRowBottom()))
                      : false;
                    return (
                      <Draggable
                        key={`Draggable-Device-${device.id}`}
                        isDragDisabled={!itemAddable}
                        draggableId={`Draggable-Device-${device.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div>
                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                              <DeviceTileSmall addable={itemAddable} device={device} selectDevice={selectDevice} refresh={refreshList} />
                            </div>
                            {snapshot.isDragging && (
                              <div id={`Draggable-Device-${device.id}-Div`}>
                                <DeviceTileSmall addable={itemAddable} device={device} selectDevice={selectDevice} refresh={refreshList} />
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {/* Scene */}
          <Portal>
            <Droppable droppableId="Droppable-Devices-Scene" ignoreContainerClipping>
              {(provided, snapshot) => (
                <div
                  className="Droppable-Devices-Scene"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ border: '5px solid ' + (snapshot.isDraggingOver ? colors['lightBlue'] : 'transparent') }}
                >
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Portal>
        </DragDropContext>
      </div>
    </Accordion>
  );
};
