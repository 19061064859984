import { FC, useContext } from 'react';

// Context
import { EditorContext, LangContext } from 'context';

// Components
import { DeviceTileSmall, LoadingSpinner } from 'components';
import { ContextMenu } from 'page/Editor/Menu/Shared';

// Lang
import Localizations from 'languages';

// Types
import { Device } from 'types/Device';

// Stlyes:
import './style.scss';

interface Props {}

export const SearchResults: FC<Props> = () => {
  const { lang } = useContext(LangContext);
  const { searching, searchResults, setSelection } = useContext(EditorContext);

  console.log('SS', searching);
  return (
    <>
      <ContextMenu initActive mode="search" />
      <div className="Search-Results">
        <h4 style={{ marginTop: '54px' }}>Search Results</h4>
        {searching ? (
          <div className="mt-4">
            <LoadingSpinner color="blue" />
          </div>
        ) : (
          <>
            {searchResults && searchResults.length > 0 ? (
              searchResults.map((result: Device, index: number) => {
                return (
                  <div className="Search-Results-Result" key={`Search-Result-${result.id}-${index}`}>
                    <DeviceTileSmall device={result} selectDevice={setSelection} />
                  </div>
                );
              })
            ) : (
              <span>{Localizations['noDevice'][lang]}</span>
            )}
          </>
        )}
      </div>
    </>
  );
};
