import { FC, useContext, useEffect, useState } from 'react';

// Components
import { Icon, Logo } from 'atoms';

// Context
import { EditorContext, LangContext } from 'context';

// Styles
import './style.scss';

// Types
import { Device } from 'types';

// Utils
import { addDevice, addFreeDevice, get, shortenText } from 'utils';
import { isSubstructureClashing } from 'utils/isSubstructureClashing';

interface Props {
  addable?: boolean;
  device: Device;
  refresh?: () => void;
  selectDevice?: (d: Device) => void;
}

export const DeviceTileSmall: FC<Props> = ({ addable = null, device, refresh, selectDevice }) => {
  const {
    addImageToStorage,
    configuration,
    deviceImageStorage,
    menuView,
    scene,
    seriesFilter,
    selectedMarineMeister,
    selectedMasterline,
    selectedModular,
    selectedModularNOL,
    setCanAdvanceMasterline,
    setCanAdvanceModular,
    setCanAdvanceModularNOL,
    setCoverEnlargementSize,
    setDialog,
    setErrorAlert
  } = useContext(EditorContext);
  const isModular = menuView === 'modular';
  const { langId, lang } = useContext(LangContext);

  //const { electroConditions, elektro, gas, steam, voltage } = device.energy;
  const [image, setImage] = useState(null);
  const freeDevice = device.model.spaceCombi || device.model.flexiChef;
  const block =
    menuView === 'modular'
      ? selectedModular
      : menuView === 'modularNOL'
      ? selectedModularNOL
      : menuView === 'masterline'
      ? selectedMasterline
      : menuView === 'marineMeister'
      ? selectedMarineMeister
      : null;

  const [itemAddable, setItemAddable] = useState<boolean>(
    addable !== null
      ? addable
      : isModular && selectedModular
      ? selectedModular.canItemBeDropped(device, selectedModular.getRowTop()) || selectedModular.canItemBeDropped(device, selectedModular.getRowBottom())
      : menuView === 'modularNOL' && selectedModularNOL
      ? selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowTop()) ||
        selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowBottom())
      : freeDevice && menuView !== 'masterline'
      ? freeDevice
      : block && menuView !== 'marineMeister'
      ? !isSubstructureClashing(device, block) && (block.canItemBeDropped(device, block.getRowTop()) || block.canItemBeDropped(device, block.getRowBottom()))
      : false
  );

  useEffect(() => {
    if (addable !== null) {
      setItemAddable(addable);
    }
  }, [addable]);

  const urlCreator = window.URL || window.webkitURL;

  useEffect(() => {
    if (isModular) {
      setItemAddable(
        selectedModular &&
          (selectedModular.canItemBeDropped(device, selectedModular.getRowTop()) || selectedModular.canItemBeDropped(device, selectedModular.getRowBottom()))
      );
    } else if (menuView === 'modularNOL') {
      setItemAddable(
        selectedModularNOL &&
          (selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowTop()) ||
            selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowBottom()))
      );
    } else if ((!freeDevice || menuView === 'masterline') && block && device) {
      setItemAddable(
        !isSubstructureClashing(device, block) && (block.canItemBeDropped(device, block.getRowTop()) || block.canItemBeDropped(device, block.getRowBottom()))
      );
    }
  }, [block, isModular, menuView, selectedModular, selectedModularNOL, device]);

  useEffect(() => {
    let isActive = true;
    const getImage = async () => {
      const imgUrl = `${process.env.REACT_APP_API_URL}/device/get/${device.id}/model/image`;
      const { data, error } = await get(imgUrl, { responseType: 'blob' });
      if (data && isActive) {
        setImage(urlCreator.createObjectURL(data));
        addImageToStorage({ id: device.id, blob: data });
      }
      if (error) {
        console.log(error);
      }
    };

    // Check if image is in Context, else fetch from DB
    if (deviceImageStorage && deviceImageStorage[device.id]) {
      setImage(urlCreator.createObjectURL(deviceImageStorage[device.id]));
    } else {
      getImage();
    }
    return () => {
      isActive = false;
    };
  }, []);

  return (
    <div className="Device-Tile-Small" onClick={() => (selectDevice ? selectDevice(device) : {})}>
      <div className="Device-Tile-Small-Image">{image ? <img src={image} alt="" /> : <Logo />}</div>

      <div className="Device-Tile-Small-Description">
        <div className="Device-Tile-Small-Description-Title" lang={lang}>
          {device.model.flexiChef ? device.code : device.translations[langId].name}
        </div>
        <div className="Device-Tile-Small-Description-Details">
          {/* <span className="Device-Tile-Small-Description-Details-Text">{`${elektro > 0 ? elektro + ' kW' : ''} ${electroConditions} ${
            gas > 0 ? gas + ' kW' : ''
          } ${steam > 0 ? steam + 'kW' : ''} ${voltage > 0 ? voltage + ' V' : ''}`}</span> */}
          <span className="Device-Tile-Small-Description-Details-Text" lang={lang}>
            {device.translations[langId].description && device.translations[langId].description.length > 140
              ? `${shortenText(device.translations[langId].description, 140)} ...`
              : device.translations[langId].description}
          </span>
        </div>
        <div
          className={'Device-Tile-Small-Description-Actions'}
          onClick={(event: any) => {
            event.stopPropagation();
            if (itemAddable) {
              if ((isModular || device.model.modular) && menuView === 'modular') {
                addDevice({ scene, device, setDialog, setErrorAlert, setCoverEnlargementSize, filter: seriesFilter, block: selectedModular });
                setCanAdvanceModular(true);
              } else if (device.model.modularNOL && menuView === 'modularNOL') {
                addDevice({
                  scene,
                  device,
                  setDialog,
                  setErrorAlert,
                  setCoverEnlargementSize,
                  filter: seriesFilter,
                  block: selectedModularNOL,
                  shouldAddWingedDoor: true,
                  shouldAddHygiene: true
                });
                setCanAdvanceModularNOL(true);
              } else {
                if (device.model && (device.model.flexiChef || device.model.spaceCombi) && menuView !== 'masterline') {
                  addFreeDevice(scene, device, configuration);
                } else {
                  addDevice({ scene, device, setDialog, setErrorAlert, setCoverEnlargementSize, filter: seriesFilter, block });
                  //selectDevice(device);
                  if (menuView === 'masterline') {
                    if (device.model.flexiChef) {
                      setErrorAlert(['flexiMasterlineHint']);
                    }
                    setCanAdvanceMasterline(true);
                  }
                }
              }
              if (refresh) {
                refresh();
              }
            }
          }}
        >
          <button className={`Device-Tile-Small-Description-Actions-Add${!itemAddable ? '-disabled' : ''}`} disabled={!itemAddable}>
            <Icon margin="auto" size="0.8rem" type={'plus'} style={{ cursor: itemAddable ? 'pointer' : 'not-allowed' }} />
          </button>
        </div>
      </div>
    </div>
  );
};
