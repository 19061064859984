import React, { FC, useContext } from 'react';

// Atoms
import { Button, Icon } from 'atoms';
import { IconType } from 'atoms/Icon';

// Components
import { ContextMenu } from 'page/Editor/Menu/Shared';

// Context
import { EditorContext, LangContext } from 'context';

// Lang
import Localizations from 'languages';

// Type
import { ErrorAlert } from 'types';

interface Props {
  confirm?: () => void;
  goBack?: () => void;
  iconType?: IconType;
  title?: string;
  message?: string;
  smallMessage?: string;
}

export const ErrorMessage: FC<Props> = ({ confirm, iconType = 'alert', goBack, message, smallMessage, title }) => {
  const { lang } = useContext(LangContext);
  const { errorAlert } = useContext(EditorContext);

  if (!title && !message && errorAlert && errorAlert.length > 1) {
    const messageKeys = {
      fryer: 'fryerHint',
      size: 'sizeConflictMessage',
      coverEnlargement: 'coverEnlargementHint'
    };

    return (
      <div>
        <ContextMenu mode="back" onClick={goBack} />
        <div className="mb-2" style={{ marginTop: '3rem' }}>
          <Icon color="medium" type={iconType} stroke={2} />
        </div>
        <div className="bold text-lg" style={{ margin: '1.25rem 0 1rem' }}>
          {Localizations['attention'][lang]}
        </div>
        {errorAlert.map((e: ErrorAlert) => (
          <div className="mb-2" style={{ fontSize: '13px', letterSpacing: '0.3px', lineHeight: '18px' }}>
            {Localizations[messageKeys[e]][lang]}
            {smallMessage && (
              <>
                <br />
                <br />
                <span style={{ fontSize: '11px', letterSpacing: '0.3px', lineHeight: '16px' }}>{smallMessage}</span>
              </>
            )}
          </div>
        ))}
        {confirm && (
          <div style={{ marginTop: '3rem' }}>
            <Button btnType="third" margin="10px 0 5px 0" padding=".5rem " fontSize=".6rem" onClick={confirm}>
              {Localizations['delete'][lang]}
            </Button>
            <Button btnType="fourth" margin="10px 0 5px 0" padding=".5rem " fontSize=".6rem" onClick={goBack}>
              {Localizations['abort'][lang]}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <ContextMenu mode="back" onClick={goBack} />
      <div className="mb-2" style={{ marginTop: '3rem' }}>
        <Icon color="medium" type={iconType} stroke={2} />
      </div>
      <div className="bold text-lg" style={{ margin: '1.25rem 0 1rem' }}>
        {title}
      </div>
      <div style={{ fontSize: '13px', letterSpacing: '0.3px', lineHeight: '18px' }}>
        {message}
        {smallMessage && (
          <>
            <br />
            <br />

            <span style={{ fontSize: '11px', letterSpacing: '0.3px', lineHeight: '16px' }}>{smallMessage}</span>
          </>
        )}
      </div>
      {confirm && (
        <div style={{ marginTop: '3rem' }}>
          <Button btnType="third" margin="10px 0 5px 0" padding=".5rem " fontSize=".6rem" onClick={confirm}>
            {Localizations['delete'][lang]}
          </Button>
          <Button btnType="fourth" margin="10px 0 5px 0" padding=".5rem " fontSize=".6rem" onClick={goBack}>
            {Localizations['abort'][lang]}
          </Button>
        </div>
      )}
    </div>
  );
};
